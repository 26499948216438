import React, { Component } from "react";
import "./style/timer.css";

import Header from "./components/Header";
import Timer from "./components/Timer";
import Controls from "./components/Controls";

class TimeView extends Component {
  constructor(props) {
    super(props);
    document.addEventListener("keyup", e => {
      if (e.keyCode === 13) this.toggleRunning();
    });
    let color = Math.floor(Math.random() * 3);
    let colors = ["grass", "red", "yellow"];
    document.querySelector("body").classList.add("color-" + colors[color]);
  }
  state = {
    running: false,
    time: [0, 0, 0, 0]
  };

  toggleRunning = () => {
    const { time } = this.state;
    if (time[0] === 0 && time[1] === 0 && time[2] === 0 && time[3] === 0) {
      return;
    }
    this.setState({ running: !this.state.running }, () => {
      if (this.state.running) {
        this.interval = setInterval(this.runTimer, 1000);
      } else {
        clearInterval(this.interval);
        this.interval = null;
      }
    });
  };

  setValue = newTime => {
    this.setState({ time: newTime });
  };

  updateTime = (digit, currentValue) => {
    const newTime = this.state.time.map((value, i) => {
      return (value = i === digit ? (value = parseInt(currentValue)) : value);
    });
    this.setState({ time: newTime });
  };

  runTimer = () => {
    const { time } = this.state;

    const newRemaining = [...time];
    newRemaining[3]--;

    if (newRemaining[3] < 0) {
      newRemaining[3] = 9;
      newRemaining[2]--;
    }
    if (newRemaining[2] < 0) {
      newRemaining[2] = 5;
      newRemaining[1]--;
    }
    if (newRemaining[1] < 0) {
      newRemaining[1] = 9;
      newRemaining[0]--;
    }

    if (
      newRemaining[0] === 0 &&
      newRemaining[1] === 0 &&
      newRemaining[2] === 0 &&
      newRemaining[3] === 0
    ) {
      this.stopTimer();
    }

    this.setState({
      time: newRemaining
    });
  };

  startAnimation = () => {
    document.querySelector("body").classList.add("animating");
    document.querySelector("body").addEventListener(
      "click",
      () => {
        document.querySelector("body").classList.remove("animating");
        this.setState({ running: false });
      },
      false
    );
  };

  stopTimer = () => {
    clearInterval(this.interval);
    this.interval = null;
    this.startAnimation();
  };

  render() {
    return (
      <div className="TimeView">
        <Header />
        <Timer
          time={this.state.time}
          updateTime={this.updateTime}
          running={this.state.running}
        />
        <Controls
          toggleRunning={this.toggleRunning}
          setValue={this.setValue}
          running={this.state.running}
        />
      </div>
    );
  }
}

export default TimeView;
