import React, { Component } from "react";

export class Digit extends Component {
  onChange = e => {
    const digit = this.props.place;
    let newValue = parseInt(e.target.value);

    this.props.updateTime(digit, newValue);
  };
  onKeyUp = e => {
    const digit = this.props.place;
    let prevValue = parseInt(e.target.value);
    let newValue = parseInt(e.key);

    switch (e.keyCode) {
      case 9: // Tab
        newValue = prevValue;
        break;
      case 38: // Arrow up
        prevValue < this.props.max
          ? (newValue = prevValue + 1)
          : (newValue = this.props.max);
        break;
      case 40: // Arrow Down
        prevValue > 0 ? (newValue = prevValue - 1) : (newValue = 0);
        break;
      case 8: // Backspace
        newValue = 0;
        break;
      case 13: // Enter
        newValue = prevValue;
        e.target.blur();
        break;
      default:
        if (isNaN(newValue)) {
          newValue = 0;
          break;
        }
        if (newValue > this.props.max) newValue = this.props.max;
        this.props.nextFocus(e);
    }
    this.props.updateTime(digit, newValue);
  };
  increment = () => {
    const digit = this.props.place;
    let prevValue = parseInt(this.props.value);
    let newValue;
    prevValue < this.props.max
      ? (newValue = prevValue + 1)
      : (newValue = this.props.max);
    this.props.updateTime(digit, newValue);
  };
  decrement = () => {
    const digit = this.props.place;
    let prevValue = parseInt(this.props.value);
    let newValue;
    prevValue > 0 ? (newValue = prevValue - 1) : (newValue = 0);
    this.props.updateTime(digit, newValue);
  };
  onFocus = e => {
    e.target.focus();
    e.target.select();
  };
  onHover = (e, place) => {
    document.querySelectorAll("#stepper-" + place).forEach(item => {
      item.style.opacity = 1;
    });
  };
  onMouseOut = (e, place) => {
    document.querySelectorAll("#stepper-" + place).forEach(item => {
      item.style.opacity = 0;
    });
  };
  render() {
    return (
      <React.Fragment>
        <button
          id={"stepper-" + this.props.place}
          className="stepper stepper-top"
          onClick={this.increment}
          onMouseOver={e => this.onHover(e, this.props.place)}
          onMouseOut={e => this.onMouseOut(e, this.props.place)}
        >
          +
        </button>
        <input
          type="number"
          step="1"
          className="digit"
          min="0"
          max={this.props.max}
          maxLength="1"
          pattern="[0-9]*"
          value={this.props.value}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onKeyUp={this.onKeyUp}
          onMouseOver={e => this.onHover(e, this.props.place)}
          onMouseOut={e => this.onMouseOut(e, this.props.place)}
          disabled={this.props.running ? "disabled" : ""}
        />
        <button
          id={"stepper-" + this.props.place}
          className="stepper stepper-bottom"
          onClick={this.decrement}
          onMouseOver={e => this.onHover(e, this.props.place)}
          onMouseOut={e => this.onMouseOut(e, this.props.place)}
        >
          –
        </button>
      </React.Fragment>
    );
  }
}

export default Digit;
