import React, { Component } from "react";

import Digit from "./Digit";
export class Timer extends Component {
  nextFocus = e => {
    let next = e.target;
    while ((next = next.nextElementSibling)) {
      if (next.tagName.toLowerCase() === "input") {
        next.focus();
        break;
      }
    }
    if (next == null) e.target.blur();
  };
  render() {
    const { time } = this.props;
    return (
      <div id="timer" className={this.props.running ? "running" : "halted"}>
        <Digit
          place={0}
          value={time[0]}
          updateTime={this.props.updateTime}
          max={5}
          nextFocus={this.nextFocus}
          running={this.props.running}
        />
        <Digit
          place={1}
          value={time[1]}
          updateTime={this.props.updateTime}
          max={9}
          nextFocus={this.nextFocus}
          running={this.props.running}
        />
        <span className="digit">:</span>
        <Digit
          place={2}
          value={time[2]}
          updateTime={this.props.updateTime}
          max={5}
          nextFocus={this.nextFocus}
          running={this.props.running}
        />
        <Digit
          place={3}
          value={time[3]}
          updateTime={this.props.updateTime}
          max={9}
          nextFocus={this.nextFocus}
          running={this.props.running}
        />
      </div>
    );
  }
}

export default Timer;
