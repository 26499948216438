import React, { Component } from "react";

export class Controls extends Component {
  toggleRunning = () => this.props.toggleRunning();
  onSetValue = (e, newTime) => {
    e.preventDefault();
    this.props.setValue(newTime);
  };
  toggleCaption = () => {
    return this.props.running ? "Stop" : "Start";
  };
  getVisibility = () => {
    if (this.props.running) {
      return {
        opacity: "0"
      };
    } else {
      return;
    }
  };
  render() {
    return (
      <div id="controls">
        <ul className="left-shortcuts" style={this.getVisibility()}>
          <li>
            <button
              className="shortcut"
              onClick={e => this.onSetValue(e, [5, 9, 5, 9])}
            >
              Eine Stunde
            </button>
          </li>
          <li>
            <button
              className="shortcut"
              onClick={e => this.onSetValue(e, [3, 0, 0, 0])}
            >
              Halbe Stunde
            </button>
          </li>
        </ul>
        <button id="cta" onClick={this.toggleRunning}>
          {this.toggleCaption()}
        </button>
        <ul className="right-shortcuts" style={this.getVisibility()}>
          <li>
            <button
              className="shortcut"
              onClick={e => this.onSetValue(e, [0, 5, 0, 0])}
            >
              Fünf Minuten
            </button>
          </li>
          <li>
            <button
              className="shortcut"
              onClick={e => this.onSetValue(e, [0, 1, 0, 0])}
            >
              Eine Minute
            </button>
          </li>
        </ul>
      </div>
    );
  }
}

export default Controls;
